import './App.css';
import WebInfoConsultancy from './WebInfoConsultancy';

function App() {
  return (
    <div>
      <WebInfoConsultancy/>
    </div>
  );
}

export default App;

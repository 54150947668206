import React, {useEffect, useState} from 'react';

const WebInfoConsultancy = () => {
    const launch_date = new Date("2024-02-15");
    const [email, setEmail] = useState('');

    
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const updateTimer = () => {
            const now = new Date();
            const difference = launch_date - now;

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeLeft({ days, hours, minutes, seconds });
        }

        const timerId = setInterval(updateTimer, 1000);

        return () => clearInterval(timerId); // Clean-up function to clear the interval when the component unmounts
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();
    
        // Fetch API or Axios can be used here
        fetch('http://localhost:3001/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: e.target.elements[0].value, // Assuming email is the first element in the form
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.message);
            // Add any additional logic you want after successfully saving the email
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div className="container">
            <div className="row">
                <div className="left-wrap col-12 col-md-5">
                <div className="left">
                        <p><span className="days">{timeLeft.days}</span><span className="small"> days</span></p>
                        <p><span className="hours">{timeLeft.hours}</span><span className="small"> hours</span></p>
                        <p><span className="minutes">{timeLeft.minutes}</span><span className="small"> minutes</span></p>
                        <p><span className="seconds">{timeLeft.seconds}</span><span className="small"> seconds</span></p>
                    </div>
                </div>
                <div className="col-12 col-md-7 right">
                    <div className="mainInfo">
                        <h1>COMING SOON</h1>
                        <p className="slogan">Weaving Digital Dreams into Reality.</p>
                        <p>Email: - contact@webinfoconsultancy.com</p>
                            <form className="form-subscribe" onSubmit={handleFormSubmit}>
                                <input 
                                    type="email" 
                                    className="form" 
                                    placeholder="Your email address" 
                                    required 
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <button type="submit">Subscribe</button>
                            </form>
                    </div>
                    <ul className="icon-list">
                        <li className="icon"><a className="twitter" href="/"></a></li>
                        <li className="icon"><a className="facebook" href="/"></a></li>
                        <li className="icon"><a className="instagram" href="/"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default WebInfoConsultancy;
